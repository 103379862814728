import React, { FC } from "react";
import { Grid } from "@mui/material";
import styles from "./RasterPassPreview.module.css";

import thumb1 from "./thumb1.png";
import thumb2 from "./thumb2.png";
import thumb3 from "./thumb3.png";

interface Props {
  templateID: string;
}

const imrSrcByID: Record<string, string> = {
  "672": thumb1,
  "671": thumb2,
  "670": thumb3,
};

export const RasterPassPreview: FC<Props> = ({ templateID }) => {
  return (
    <>
      <Grid container>
        <Grid
          item
          className={styles.previewContainer}
          xs={12}
          sx={{
            flexDirection: "column",
          }}
        >
          <img
            src={imrSrcByID[templateID]}
            className={styles.image}
            alt="Preview"
          />
        </Grid>
      </Grid>
    </>
  );
};
