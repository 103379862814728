import { createErrorMessage, MessageBus } from "@roketus/web-toolkit";
import axios from "axios";
import { filter } from "rxjs";
import { isAuthMessageEntity } from "../../domain/entities/messages/authEntity/authEntity";
import { ERROR_CODE_HTTP_401 } from "../../domain/specs/errorCodes";
import { isUndefined } from "lodash";
import { DEFAULT_TIMEOUT } from "./timeout";

export const init = (messageBus: MessageBus) => {
  const client = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: DEFAULT_TIMEOUT,
  });

  client.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (401 === error.response.status) {
        messageBus.send(
          createErrorMessage({
            code: ERROR_CODE_HTTP_401,
            message: "Unauthorized",
            source: "privateClient",
          })
        );
      } else {
        return Promise.reject(error);
      }
    }
  );

  messageBus.stream$.pipe(filter(isAuthMessageEntity)).subscribe((authData) => {
    if (isUndefined(authData.data.apiKey)) return;

    client.defaults.headers.common[
      "Authorization"
    ] = `ApiKey ${authData.data.apiKey}`;
  });

  return client;
};
