import axios from "axios";
import { getContainer } from "../../diContainer/container";
import {
  AuthService,
  IAuthState,
} from "../../boundary/AuthService/AuthService";
import {
  createErrorMessage,
  ErrorMessage,
  ErrorMessageBus,
  isErrorMessage,
  MessageBus,
  PersistentStorage,
  StateSubject,
} from "@roketus/web-toolkit";
import queryString from "query-string";
import {
  RefreshTokenResponseDTO,
  LoginResponseDTO,
  ObtainLocalAccessTokenDTO,
} from "../../boundary/AuthService/AuthServiceDTOs";
import {
  ERROR_CODE_HTTP_401,
  ERROR_CODE_LOGIN_BY_APIKEY_FAILED,
  ERROR_CODE_LOGIN_FAILED,
} from "../../domain/specs/errorCodes";
import { ClientResponse } from "../../boundary/HTTPClient";
import {
  AuthDataEntity,
  AuthMessageEntity,
} from "../../domain/entities/messages/authEntity/authEntity";
import {
  createAuthDataEntity,
  createAuthMessageEntity,
} from "../../domain/entities/messages/authEntity/authEntityBuilder";
import { filter } from "rxjs";
import { IProfileService } from "../../boundary/ProfileService/ProfileService";
import { sdk } from "@roketus/loyalty-js-sdk";
import { DEFAULT_TIMEOUT } from "../clients/timeout";

const client = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: DEFAULT_TIMEOUT,
});

const AUTH_DATA_KEY = "authorizationData";

const stateMachine = new StateSubject<IAuthState>({
  isAuth: false,
  userName: process.env.REACT_APP_USER || "",
  useRefreshTokens: false,
  isLoaded: false,
});

const init = (): void => {
  handleUnauthorizedMessage();
};

const loginByApiKey = async function (apiKey: string) {
  const container = getContainer();
  const messageBus = container.getDependency("messageBus") as MessageBus;

  try {
    const jwtResponse = await sdk.auth.getUserTokenByIssuerAPIKey(apiKey);

    const payload = {
      isLoaded: true,
      isAuth: true,
      token: jwtResponse,
      apiKey: apiKey,
      useRefreshTokens: false,
      userName: process.env.REACT_APP_USER || "",
    };
    stateMachine.setState(payload);
    const authData = createAuthMessageEntity("authData", payload);
    messageBus.send<unknown>(authData);
  } catch (e) {
    const errorMessage = createErrorMessage({
      code: ERROR_CODE_LOGIN_BY_APIKEY_FAILED,
      source: "authService",
      message: "Login by ApiKey failed",
      error: e as Error,
    });
    messageBus.send(errorMessage);
  }
};

const handleUnauthorizedMessage = function () {
  const errorMessageBus = getContainer().getDependency(
    "errorMessageBus"
  ) as ErrorMessageBus;

  errorMessageBus.stream$
    .pipe(
      filter(
        (message: ErrorMessage) => message.data.code === ERROR_CODE_HTTP_401
      )
    )
    .subscribe(logOut);
};

const login = async function (loginData: {
  userName: string;
  password: string;
  useRefreshTokens: boolean;
}) {
  const container = getContainer();
  const localStorageService = container.getDependency(
    "persistentStorage"
  ) as PersistentStorage;
  const messageBus = container.getDependency("messageBus") as MessageBus;

  const { userName, password, useRefreshTokens } = loginData;
  const dataConfig = {
    grant_type: "password",
    username: userName,
    password: password,
  };

  if (useRefreshTokens) {
    // @TODO where to get clientId?
    // data = data + "&client_id=" + ngAuthSettings.clientId;
  }

  const data = queryString.stringify(dataConfig);

  try {
    const response: ClientResponse<LoginResponseDTO> = await client.post(
      "token",
      data,
      {
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      }
    );

    let authData: AuthDataEntity | ErrorMessage | null;
    if (loginData.useRefreshTokens) {
      authData = createAuthDataEntity({
        isAuth: true,
        token: response.data.access_token,
        userName: loginData.userName,
        useRefreshTokens: true,
      });
    } else {
      authData = createAuthDataEntity({
        isAuth: true,
        token: response.data.access_token,
        userName: loginData.userName,
        refreshToken: "",
        useRefreshTokens: false,
      });
    }

    if (isErrorMessage(authData)) {
      messageBus.send(authData);
      return response.data;
    }

    const authMessage: AuthMessageEntity = {
      type: "authEntityChange",
      message: "",
      source: "authService",
      data: authData,
    };

    messageBus.send(authMessage);
    localStorageService.setItem(AUTH_DATA_KEY, authData);
    stateMachine.setState({ ...authData, isLoaded: true });

    return response.data;
  } catch (e: unknown) {
    const messageBus = container.getDependency(
      "errorMessageBus"
    ) as ErrorMessageBus;
    const errorMessage = createErrorMessage({
      code: ERROR_CODE_LOGIN_FAILED,
      source: "authService",
      message: "Login failed",
      error: e as Error,
    });
    messageBus.send(errorMessage);

    logOut();
    return Promise.reject("AuthService login error");
  }
};

const logOut = function () {
  const localStorageService = getContainer().getDependency(
    "persistentStorage"
  ) as PersistentStorage;
  const profileService = getContainer().getDependency(
    "profileService"
  ) as IProfileService;

  localStorageService.removeItem(AUTH_DATA_KEY);
  localStorageService.removeItem(AUTH_DATA_KEY);

  stateMachine.setState({
    isAuth: false,
    userName: "",
    useRefreshTokens: false,
    isLoaded: false,
  });

  // @TODO invers dependecy trhough messageBus, every service should do cleanup on its own
  profileService.cleanUpState();
  window.location.reload();
};

const fillAuthData = function () {
  const localStorageService = getContainer().getDependency(
    "persistentStorage"
  ) as PersistentStorage;

  const authData = localStorageService.getItem<AuthDataEntity | undefined>(
    AUTH_DATA_KEY
  );

  if (authData) {
    stateMachine.setState({
      isAuth: true,
      userName: authData.userName,
      useRefreshTokens: authData.useRefreshTokens,
      isLoaded: true,
    });
  }
};

const refreshToken = async function () {
  const localStorageService = getContainer().getDependency(
    "persistentStorage"
  ) as PersistentStorage;

  const authData = localStorageService.getItem<AuthDataEntity | undefined>(
    AUTH_DATA_KEY
  );

  if (authData) {
    if (authData.useRefreshTokens) {
      const dataConfig = {
        grant_type: "refresh_token",
        refresh_token: authData.refreshToken,
        // client_id: ngAuthSettings.clientId
      };

      const data = queryString.stringify(dataConfig);

      localStorageService.removeItem(AUTH_DATA_KEY);

      try {
        const response: RefreshTokenResponseDTO = await client.post(
          "token",
          data,
          {
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
          }
        );

        localStorageService.setItem(AUTH_DATA_KEY, {
          token: response.access_token,
          userName: response.userName,
          refreshToken: response.refresh_token,
          useRefreshTokens: true,
        });

        return response;
      } catch (e) {
        logOut();
        return Promise.reject(e);
      }
    }
  }

  return Promise.reject(Error("No refresh token found"));
};

const obtainAccessToken = async function (externalData: {
  provider: string;
  externalAccessToken: string;
}) {
  const localStorageService = getContainer().getDependency(
    "persistentStorage"
  ) as PersistentStorage;

  try {
    const response: ObtainLocalAccessTokenDTO = await client.get(
      "api/Account/ObtainLocalAccessToken",
      {
        params: {
          provider: externalData.provider,
          externalAccessToken: externalData.externalAccessToken,
        },
      }
    );

    localStorageService.setItem(AUTH_DATA_KEY, {
      token: response.access_token,
      userName: response.userName,
      refreshToken: "",
      useRefreshTokens: false,
    });

    stateMachine.setState({
      isAuth: true,
      userName: response.userName,
      useRefreshTokens: false,
      isLoaded: true,
    });

    return response;
  } catch (e) {
    logOut();
    return Promise.reject(e);
  }
};

const saveRegistration = async function (registration: unknown) {
  logOut();

  return client.post("api/account/register", registration);
};

export const authService: AuthService = {
  init,
  login,
  logOut,
  fillAuthData,
  refreshToken,
  obtainAccessToken,
  saveRegistration,
  loginByApiKey,
  state$: stateMachine.state$,
};
